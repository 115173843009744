import axios from 'axios';

const token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
  axios.defaults.headers.common['X-CSRF-Token'] = token.content;
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = '/api';

export const getProject = async (projectName) => {
  const { data } = await axios.get(`/projects/${projectName}`);
  return data;
};

export const getProjectLangs = async (projectId) => {
  const { data } = await axios.get(`/projects/${projectId}/languages`);
  return data;
};
