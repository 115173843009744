import {
  RiFacebookBoxFill as FacebookIcon,
  RiInstagramLine as InstagramIcon,
  RiTwitterLine as TwitterIcon,
} from 'react-icons/ri';

import React from 'react';
import seekrtechIcon from '../../assets/seekrtech_icon.svg';
import { localize } from '../../helpers';

function Footer({ project, language }) {
  return (
    <div
      className='Footer'
      style={{ backgroundColor: project.secondary_color }}
    >
      <div className='content'>
        <div className='logo'>
          <a href='https://www.seekrtech.com/' target='_blank'>
            <img src={seekrtechIcon} alt='seekrtech icon' />
          </a>
        </div>
        <div className='text'>
          <a className='terms' href={project.terms_link} target='_blank'>
            {localize(language, 'footer_service')}
          </a>
          <a className='privacy' href={project.privacy_link} target='_blank'>
            {localize(language, 'footer_privacy')}
          </a>
          <a className='contact-us' href={project.contact_link} target='_blank'>
            {localize(language, 'footer_contact')}
          </a>
        </div>
        <div className='social-media' style={{ color: '#FFF' }}>
          <a href={project.facebook_link} target='_blank'>
            <FacebookIcon />
          </a>
          <a href={project.instagram_link} target='_blank'>
            <InstagramIcon />
          </a>
          <a href={project.twitter_link} target='_blank'>
            <TwitterIcon />
          </a>
        </div>
      </div>
      <div className='rights'>
        © {new Date().getFullYear()} Seekrtech, All Rights Reserved.
      </div>
    </div>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
