import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from './layout/PageNotFound';
import Project from './Project';

export default function App() {
  return (
    <div className='App'>
      <Switch>
        <Route path='/:project' component={Project} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}
