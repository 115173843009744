import lokalize from '../../lokalise.json';

export const toMap = (array, key_name) => {
  return array.reduce(function (map, obj) {
    map[obj[key_name]] = { ...obj };
    return map;
  }, {});
};

export const localize = (currentProjectLanguage, key) => {
  return lokalize[currentProjectLanguage.language.code][key];
};

export const getUserLang = (projectName) => {
  const url = new URL(window.location.href);
  let langCode =
    url.searchParams.get('language') ||
    localStorage.getItem(`${projectName}_language_desktop`) ||
    window.navigator.language ||
    window.navigator.userLanguage;
  langCode = langCode.replace(/[-_](.*)/, (match, p1) => '_' + p1.toUpperCase());
  return langCode;
};
