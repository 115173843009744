const initialState = {
  isProjectLoaded: false,
  currentProject: null,
  currentProjectLanguage: null,
  currentProjectLanguages: [],
  currentProjectLanguagesMap: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_IS_PROJECT_LOADED':
      return {
        ...state,
        isProjectLoaded: action.payload,
      };
    case 'UPDATE_CURRENT_PROJECT':
      return {
        ...state,
        currentProject: action.payload,
      };
    case 'CLEAR_CURRENT_PROJECT':
      return {
        ...state,
        currentProject: initialState.currentProject,
      };
    case 'UPDATE_CURRENT_PROJECT_LANGUAGE':
      return {
        ...state,
        currentProjectLanguage: action.payload,
      };
    case 'CLEAR_CURRENT_PROJECT_LANGUAGE':
      return {
        ...state,
        currentProjectLanguage: initialState.currentProjectLanguage,
      };
    case 'UPDATE_CURRENT_PROJECT_LANGUAGES':
      return {
        ...state,
        currentProjectLanguages: action.payload,
      };
    case 'CLEAR_CURRENT_PROJECT_LANGUAGES':
      return {
        ...state,
        currentProjectLanguages: initialState.currentProjectLanguages,
      };
    case 'UPDATE_CURRENT_PROJECT_LANGUAGES_MAP':
      return {
        ...state,
        currentProjectLanguagesMap: action.payload,
      };
    case 'CLEAR_CURRENT_PROJECT_LANGUAGES_MAP':
      return {
        ...state,
        currentProjectLanguagesMap: initialState.currentProjectLanguagesMap,
      };
    default:
      return state;
  }
};
