import { getCountryForTimezone } from 'countries-and-timezones';
import { QRCodeSVG as QRCode } from 'qrcode.react';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getProject, getProjectLangs } from '../api';
import forestBg from '../assets/forest_background.svg';
import sleepTownBg from '../assets/sleeptown_background.svg';
import waterDoBg from '../assets/waterdo_background.svg';
import { getUserLang, localize } from '../helpers';
import Footer from './layout/Footer';
import Loading from './layout/Loading';
import Navbar from './layout/Navbar';
import PageNotFound from './layout/PageNotFound';

export default function Project() {
  const params = useParams();
  const { data: project = null, error } = useSWR(
    `/projects/${params.project}`,
    () => getProject(params.project),
  );
  const { data: languages = null } = useSWR(
    project?.id && `/projects/${project.id}/languages`,
    () => getProjectLangs(project.id),
  );
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (languages) {
      const userLang = getUserLang(params.project);
      const language =
        languages.find(({ language }) => language.code === userLang) ||
        languages.find(({ language }) => language.code === 'en') ||
        null;
      if (language === null) {
        alert('No language is available');
      }
      setLanguage(language);
    }
  }, [languages]);

  const getBackgroundImg = () => {
    switch (project.name) {
      case 'Forest':
        return forestBg;
      case 'SleepTown':
        return sleepTownBg;
      case 'WaterDo':
        return waterDoBg;
      default:
        return undefined;
    }
  };

  const qrCodeUrl = useMemo(() => {
    const { href, origin, pathname, search } = location;
    const searchParams = new URLSearchParams(search);
    const region = searchParams.get('region') || null;
    if (region) return href;

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const country = getCountryForTimezone(timeZone) || null;
    if (country === null) return href;

    searchParams.append('region', country.id);
    const searchParamsStr = searchParams.toString();
    const query = searchParamsStr.length === 0 ? '' : `?${searchParamsStr}`;
    return `${origin}${pathname}${query}`;
  }, []);

  if (project === null || languages === null || language === null) {
    return <Loading />;
  }
  return error?.response?.status === 404 ? (
    <PageNotFound />
  ) : (
    <div
      className='Project'
      style={
        project.name === 'WaterDo'
          ? {
              backgroundImage: `url(${getBackgroundImg()})`,
              backgroundColor: '#fff',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center calc(50% + 60px)',
              backgroundSize: '1440px',
            }
          : {
              background: `url(${getBackgroundImg()}) bottom 119px right / 100% auto border-box, ${
                project.background_color
              }`,
              backgroundRepeat: 'no-repeat',
            }
      }
    >
      <Navbar
        project={project}
        languages={languages}
        language={language}
        setLanguage={setLanguage}
      />
      <div className='container'>
        <div className={`help-text ${project.name}`}>
          <div className='header'>{localize(language, 'title_help')}</div>
          <div className='content'>{localize(language, 'service_qrcode')}</div>
        </div>
        <div
          className='qr-code'
          style={
            project.name === 'WaterDo'
              ? {
                  borderRadius: '36px',
                  minWidth: 306,
                  minHeight: 306,
                }
              : null
          }
        >
          <QRCode value={qrCodeUrl} size={260} level={'M'} />
        </div>
      </div>
      <Footer project={project} language={language} />
    </div>
  );
}
