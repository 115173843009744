import React from 'react';
import PropTypes from 'prop-types';

function Loading(props) {
  return (
    <div className='Loading'>
      <div className='spinner' style={{ borderRightColor: props.color }} />
    </div>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: '#349CC8',
};

export default Loading;
