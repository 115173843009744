import React from 'react';
import PropTypes from 'prop-types';

function GlobeIcon(props) {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.3984 18.4688C12.6094 20.3906 12.9609 22.0078 13.4062 23.1797C13.9219 23.2734 14.4609 23.3437 15 23.3437C15.5391 23.3437 16.0781 23.2969 16.5938 23.1797C17.0391 22.0078 17.3906 20.3906 17.6016 18.4688H12.3984Z'
        fill={props.fill}
      />
      <path
        d='M12.2813 13.0781C12.2344 13.7109 12.2344 14.3438 12.2344 15C12.2344 15.8438 12.2578 16.6406 12.3281 17.3906H17.7187C17.7656 16.6172 17.8125 15.8203 17.8125 15C17.8125 14.3438 17.7891 13.6875 17.7656 13.0781H12.2813Z'
        fill={props.fill}
      />
      <path
        d='M18.7031 12H22.7578C21.8906 9.75 20.0859 7.96875 17.7891 7.17188C18.2344 8.55469 18.5391 10.2422 18.7031 12Z'
        fill={props.fill}
      />
      <path
        d='M18.8438 15C18.8438 15.7969 18.8203 16.6172 18.75 17.3906H22.9688C23.2031 16.6406 23.3203 15.8203 23.3203 15C23.3203 14.3438 23.25 13.6875 23.0859 13.0781H18.7734C18.8203 13.7109 18.8438 14.3672 18.8438 15Z'
        fill={props.fill}
      />
      <path
        d='M13.4062 6.84375C12.9375 8.10938 12.5391 9.89062 12.3516 12.0234H17.6484C17.4609 9.89062 17.0625 8.10938 16.5937 6.84375C16.0781 6.75 15.5391 6.67969 15 6.67969C14.4609 6.67969 13.9219 6.72656 13.4062 6.84375Z'
        fill={props.fill}
      />
      <path
        d='M11.2969 12C11.4609 10.2188 11.7656 8.53125 12.2109 7.14844C9.91406 7.96875 8.10938 9.75 7.24219 12H11.2969Z'
        fill={props.fill}
      />
      <path
        d='M11.3438 18.4688H7.42969C8.36719 20.5078 10.0781 22.1016 12.2109 22.8516C11.8125 21.5859 11.5078 20.0625 11.3438 18.4688Z'
        fill={props.fill}
      />
      <path
        d='M18.6562 18.4688C18.4922 20.0625 18.1875 21.5859 17.7891 22.8516C19.8984 22.1016 21.6328 20.5078 22.5703 18.4688H18.6562Z'
        fill={props.fill}
      />
      <path
        d='M11.1562 15C11.1562 14.3672 11.1797 13.7109 11.2031 13.0781H6.91406C6.77344 13.6875 6.67969 14.3438 6.67969 15C6.67969 15.8438 6.79688 16.6406 7.03125 17.3906H11.25C11.2031 16.6172 11.1562 15.7969 11.1562 15Z'
        fill={props.fill}
      />
    </svg>
  );
}

GlobeIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

GlobeIcon.defaultProps = {
  fill: '#333333',
};

export default GlobeIcon;
