import React from 'react';
import PropTypes from 'prop-types';

function PageNotFound(props) {
  return <div>404</div>;
}

PageNotFound.propTypes = {};

export default PageNotFound;
