import { MdArrowDropDown as ArrowDropDownIcon } from 'react-icons/md';

import React from 'react';
import forest2Icon from '../../assets/forest-2-icon.svg';
import forestIcon from '../../assets/forest_icon.svg';
import plantyTimeIcon from '../../assets/planty-time-icon.svg';
import sleeptownIcon from '../../assets/sleeptown_icon.svg';
import waterdoIcon from '../../assets/waterdo_icon.svg';
import { localize } from '../../helpers';
import GlobeIcon from './GlobeIcon';

function Navbar({ project, languages, language, setLanguage }) {
  const onLangSelect = ({ target }) => {
    setLanguage(
      languages.find(({ language }) => language.code === target.value),
    );
  };

  const getCurrentProjectIcon = () => {
    switch (project.name) {
      case 'Forest':
        return forestIcon;
      case 'SleepTown':
        return sleeptownIcon;
      case 'WaterDo':
        return waterdoIcon;
      case 'PlantyTime':
        return plantyTimeIcon;
      case 'Forest2':
        return forest2Icon;
      default:
        return undefined;
    }
  };

  return (
    <div className='Navbar'>
      <div className='icon'>
        <img src={getCurrentProjectIcon()} alt='icon' />
      </div>
      <div className='title' style={{ color: project.text_color }}>
        {project.name} {localize(language, 'title_faq')}
      </div>
      <div className='language-select'>
        <GlobeIcon fill={project.text_color} />
        <select
          value={language.language.code}
          style={{ color: project.text_color, cursor: 'pointer' }}
          onChange={onLangSelect}
        >
          {languages.map((language) => (
            <option key={language.language.id} value={language.language.code}>
              {language.language.name}
            </option>
          ))}
        </select>
        <ArrowDropDownIcon style={{ color: project.text_color }} />
      </div>
    </div>
  );
}

Navbar.propTypes = {};

export default Navbar;
