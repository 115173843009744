import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers';

const logger = (store) => (next) => (action) => {
  if (process.env.NODE_ENV !== 'development') return next(action);
  console.group(action.type ? action.type : 'THUNK');
  console.info('dispatching', action);
  let result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();
  return result;
};

const store = createStore(
  rootReducer,
  applyMiddleware(
    ReduxThunk,
    // logger
  ),
);

export default store;
